<template>
  <app-dialog
    :submitting="status === 'pending'"
    :title="session.course.title"
    :width="1100"
    @submit="execute"
  >
    <template #subtitle>
      <session-registration-dialog-header :session="session" />
    </template>

    <registration-batch-import v-if="!hasLines" v-model="lines" />

    <template v-if="result">
      <registration-remaining-seats-limit-exceeded-callout
        v-if="result.hasExceededRemainingSeatsLimit"
        :registrations-count="result.effectiveUniqRegistrationsCount"
        :remaining-seats="result.session.remaining_seats!"
      />

      <registration-batch-session-entries :entries="result.entries" />
    </template>

    <template v-if="result" #footer>
      <div class="flex items-center justify-between gap-4">
        <div>
          <registration-validator-error-callout
            v-if="hasErrors"
            @reset="reset"
          />
        </div>

        <app-button
          class="shrink-0"
          :disabled="hasErrors || validRegistrationsCount === 0"
          type="submit"
          >{{
            $t("registration.validation_cta", {
              count: validRegistrationsCount,
            })
          }}</app-button
        >
      </div>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import { useRegistrationExecutor } from "~/business-areas/registration/composables/registration-executor.hook";
import type { SessionListView } from "~/business-areas/session/session.model";

const properties = defineProps<{
  session: SessionListView;
}>();

const { organization } = useCurrentOrganization();
const {
  lines,
  hasErrors,
  hasLines,
  entriesPerAvailableSession,
  validRegistrationsCount,
  reset,
} = await useSessionBatchRegistrationValidator({
  organization_id: organization.value!.id,
  sessions: [properties.session.id],
});

const result = computed(() =>
  entriesPerAvailableSession.value?.find(
    (item) => item.session.id === properties.session.id,
  ),
);

const { execute, status } = useRegistrationExecutor(
  organization.value!.id,
  computed(() => (result.value ? [result.value] : [])),
  {
    onSuccess() {
      useDialog("SessionBatchRegistrationDialog").close({
        confirmed: true,
      });
    },
  },
);
</script>
